<template>
  <div class="travel-plan-detail" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
    <van-row type="flex" justify="center" align="center" class="card">
      <van-col span="17">
        <van-field style="border-radius: 6px 0 0 0;" :readonly=readonly label="标题"
                   v-model="cardInfo.title"></van-field>
        <van-field label="计划出发时间" :readonly=readonly v-model="cardInfo.startTime" @click="showCalender()"></van-field>
        <van-calendar v-model="show" @confirm="onConfirm" :show-confirm="false"/>
        <van-field style="border-radius: 0 0 0 6px;" label="计划返回时间" :readonly=readonly  @click="showCalender1()"
                   v-model="cardInfo.endTime"></van-field>
        <van-calendar v-model="show1" @confirm="onConfirm1" :show-confirm="false"/>
      </van-col>
      <van-col span="7">
        <van-row>
          <van-icon name="envelop-o"/>
        </van-row>
        <van-row>
           <span>
            <button style="background-color: #378FF2; border:none" :disabled="flag" @click="submitYkb()">提交易快报
            </button>
          </span>
        </van-row>
      </van-col>
    </van-row>

    <div class="list" v-for="(item,index) in list" v-bind:key="index">
      <van-row type="flex" justify="start" align="center">
        <van-swipe-cell style="width: 100%; height: 100%">
          <van-col span="20">
            <van-row class="list-title">
              {{ item.customerName }}
            </van-row>
            <van-row>
              <van-col class="list-info" span="4">{{ item.createdPeople }}</van-col>
              <van-col class="list-info" span="6">{{ item.enterpriseNature }}</van-col>
              <van-col class="list-info" span="6">{{ item.enterpriseType }}</van-col>
              <van-col class="list-info" span="8">{{ item.group }}</van-col>
            </van-row>
            <van-row class="list-info">
              {{ item.addressDetailed }}
            </van-row>
          </van-col>
          <van-col span="4" @click="to(item.to)">
            <van-icon style="margin-top: 30px" :name='item.ykbStatus == "00" ? "edit":"eye-o" '/>
          </van-col>
          <template slot="right">
            <van-button square type="danger" @click="deleteList(item.travelPlanid)" text="删除"/>
          </template>
        </van-swipe-cell>
      </van-row>
    </div>
    <!-- 添加按钮-->
    <div class="add">
      <van-button class="add_button" color="#FD843E" icon="plus" @click="toAdd()"/>
    </div>
    <!-- 更新按钮-->
<!--    <div class="update">-->
<!--      <van-button class="add_button" color="#378FF2" icon="success" @click="toUpdate()"/>-->
<!--    </div>-->
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import '@vant/touch-emulator';
import CrmTabBar from "@/components/CrmTabBar";
import Add from '@/components/Add';
import {Toast} from "vant";

export default {
  name: "BulkCrmsTravelPlanDetail",
  components: {CrmTabBar, Add},
  data() {
    return {
      flag: false,
      date: '',
      date1: '',
      freshHeight: 0,
      show: false,
      show1: false,
      cardInfo: {
        startTime: '',
        endTime: '',
        title: ''
      },
      list: [],
      readonly: true
    }
  },
  mounted() {
    this.freshHeight = 2500
    this.init()
  },
  methods: {
    to(to) {
      this.$router.push(to);
    },
    init() {
      this.$ajax.post('/api/ajax/planVisit/getBulkCrmPlanvisitList.json',
          {
            titleId: this.$route.params.id,
            createdPeople: this.$store.getters.userId,
            travelPlanid: ''
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.list = res.data
            this.cardInfo.title = res.data[0].titleName
            this.cardInfo.startTime = res.data[0].plandateStart
            this.cardInfo.endTime = res.data[0].plandateEnd
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].to = '/BulkCrmsTravelPlanRecord/' + this.list[i].travelPlanid
            }
            if (this.list[0].ykbStatus == "00") {
              this.readonly = false
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    toAdd() {
      if (this.cardInfo.title == '' || this.cardInfo.startTime == '' || this.cardInfo.endTime == '') {
        this.$message.info('请补全标题信息！');
      } else {
        if (this.list[0].ykbStatus == '00') {
          console.log("/BulkCrmsTravelPlanRecordAdd/" + this.cardInfo.title + "/" + this.$route.params.id + "/" + this.cardInfo.startTime + "/" + this.cardInfo.endTime)
          this.$router.push({path: "/BulkCrmsTravelPlanRecordAdd/" + this.cardInfo.title + "/" + this.$route.params.id + "/" + this.cardInfo.startTime + "/" + this.cardInfo.endTime + "/edit"});
        } else {
          this.$message.info('当前易快报状态不允许添加客户！');
        }
      }
    },
    toUpdate(){
      if (this.cardInfo.title.length == 0 || this.cardInfo.startTime.length == 0 || this.cardInfo.endTime.length == 0) {
        this.$message.warning('请补全标题及时间信息！');
      }else{
        Toast.loading({
          message: '更新中...',
          forbidClick: true,
          loadingType: 'spinner'
        });
        this.$ajax.post('/api/ajax/planVisit/updateTitlePlanvisit.json',
            {
              titleId: this.list[0].titleId,
              titleName: this.cardInfo.title,
              plandateStart: this.cardInfo.startTime,
              plandateEnd: this.cardInfo.endTime
            }).then(res => {
          if (res.status == 200) {
            console.log(res.data)
            Toast.success('更新成功');
            this.init()
          }
        }).catch(err => {
          Toast.fail('更新失败');
        })
      }
    },
    submitYkb() {
      if (this.list[0].ykbStatus == '00') {
        // Toast.loading({
        //   message: '生成中...',
        //   forbidClick: true,
        //   loadingType: 'spinner'
        // });
        this.flag = true
        var customer = ''
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].interviewType.length == 0 || this.list[i].goalPlan.length == 0 || this.list[i].length == 0) {
            customer = this.list[i].customerName
            break;
          }
        }
        if (customer.length == 0) {
          this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisitStatus.json',
              {
                titleId: this.list[0].titleId,
                createdPeople: this.$store.getters.userId,
                titleName: this.cardInfo.title,
                plandateStart: this.cardInfo.startTime,
                plandateEnd: this.cardInfo.endTime
              }).then(res => {
            if (res.status == 200) {
              console.log(res.data)
              if(res.data == "-1"){
                this.$message.error('易快报未生成，请联系运管中心！');
                this.flag = false
              }else{
                this.$message.success('提交成功！');
                //重新初始化查询
                setTimeout(() => {
                  this.$router.push({path: '/BulkCrmsTravelPlanList'})
                }, 200);
              }
            }
          }).catch(err => {
            Toast.fail('提交失败');
            console.log(err)
            this.$message.error('提交失败！');
          })
        }else {
          // Toast.fail();
          this.$message.info('请补全' + customer + '！');
        }
      } else {
        this.$message.info('不允许重复提交易快报！');
      }
    },
    deleteList(item) {
      if (this.list[0].ykbStatus == '00') {
        this.$ajax.post('/api/ajax/planVisit/deleteBulkCrmPlanvisit.json',
            {
              travelPlanid: item
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('删除成功！');
            //重新初始化查询
            setTimeout(() => {
              this.init()
            }, 200);
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('删除失败！');
        })
      } else {
        this.$message.warning('当前状态不允许删除！');
      }
    },
    onConfirm(date) {
      if (!this.readonly) {
        this.show = false;
        this.cardInfo.startTime = this.formatDate(date);
      }
    },
    onConfirm1(date) {
      if (!this.readonly) {
        this.show1 = false;
        this.cardInfo.endTime = this.formatDate(date);
      }
    },
    showCalender(){
      if (!this.readonly) {
        this.show = true;
      }
    },
    showCalender1(){
      if (!this.readonly) {
        this.show1 = true;
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  }
}
</script>

<style scoped>
.travel-plan-detail {
  background: #f3f3f3;
}

.travel-plan-detail .card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.travel-plan-detail .list {
  background: #FFFFFF;
  padding: 5px 10px;
  margin-top: 5px;
}

.travel-plan-detail .list-title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.travel-plan-detail .list-info {
  padding-top: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.travel-plan-detail .add .add_button {
  position: fixed;
  top: 75%;
  left: 80%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #F98433;
}

.travel-plan-detail .update .add_button {
  position: fixed;
  top: 82%;
  left: 80%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #F98433;
}
</style>